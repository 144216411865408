














































































import {
  Component,
  Inject,
  InjectReactive,
  Vue,
} from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import VbDialog from '@/components/VbDialog.vue';
import { User } from '@/models/entities/User';
import { Course } from '@/models/entities/Course';
import {
  RolesForCourse,
  UserRoleIdsIri,
  UserRoleIriNames,
} from '@/types/UserRoleIds';
import { ApiFacade } from '@/services/ApiFacade';
import { HydraError } from '@/api/types/HydraTypes';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { AppStore } from '@/store/types/AppStore';
import LoadingContent from '@/components/LoadingContent.vue';

@Component({
  components: {
    LoadingContent,
    ErrorAlert,
    VbDialog,
  },
})
export default class CourseStudentsAddUserFormView extends Vue {
  @InjectReactive() course!: Course;
  @Inject() apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;

  @Inject() isCourseTeacher!: () => boolean;
  @Inject() isCourseAdmin!: () => boolean;

  shouldBeRemoved: boolean = false;

  private user: User | null = null;

  isInProcess: boolean = false;

  isLoading: boolean = false;

  role: RolesForCourse = UserRoleIdsIri.student;
  error: HydraError | null = null;

  get allowedRoles(): Partial<Record<RolesForCourse, string>> {
    if (this.appStore.getters.profile?.isSuperAdmin || this.isCourseAdmin()) {
      return {
        [UserRoleIdsIri.admin]: UserRoleIriNames[UserRoleIdsIri.admin],
        [UserRoleIdsIri.teacher]: UserRoleIriNames[UserRoleIdsIri.teacher],
        [UserRoleIdsIri.student]: UserRoleIriNames[UserRoleIdsIri.student],
      };
    }

    return {
      [UserRoleIdsIri.student]: UserRoleIriNames[UserRoleIdsIri.student],
    };
  }

  get title() {
    return 'Редактирование участника';
  }

  mounted() {
    const userId = parseInt(this.$route.params.userId, 10);
    const rel = this.course.courseUsers.find((r) => r.user.userId === userId);
    if (!rel) {
      return;
    }
    this.user = rel.user || null;
    this.role = UserRoleIdsIri[rel.userRole.userRoleId];
  }

  handleClose() {
    this.$router.push({
      name: 'course-students',
      params: { courseId: String(this.course.courseId) },
    });
  }

  async handleSave() {
    if (!this.user) {
      return;
    }
    const clonedUser = cloneDeep(this.user);
    try {
      this.isInProcess = true;

      if (this.shouldBeRemoved) {
        await this.apiFacade.removeCourseUserRelation(this.course, clonedUser);
        return;
      }

      await this.apiFacade.updateCourseUserRelation(this.course, clonedUser, this.role);
    } catch (err) {
      this.error = err;
    } finally {
      this.isInProcess = false;
    }

    this.$emit('update-user', clonedUser);
    this.handleClose();
  }
}
